import React from "react";
import './cta.css';
import Faq from '../faq/faq.js'

function CTA() {

    return (
        <div className="gpt3__cta">
            <div className="gpt3__cta-content">
                <h3>FAQ</h3>
                <Faq />
            </div>
        </div>
    );
}

export default CTA;