import React from "react";
import home from '../../assets/images/website/home.png';
import MintButton from '../../components/buttons/MintButton'
import './header.css';

function Header(props) {

    return (
        <div className="gpt3__header section__padding" id="home">
            <div className="gpt3__header-content">
                <h1 className="gradient__text">Quandefy</h1>
                <h4 className="gradient__text">Empowering the retail trader. Defying the odds.</h4>
                <p>
                    Building your go-to decentralised portal for easy implementation of automated algorithmic and 
                    machine learning powered trading strategies, to trade your favourite cryptocurrencies on popular DEXs.
                </p>
                <p>
                    Mint your Quandium NFT here to be one of our earliest investors.
                    <br/><br/>
                    Mint Price: TBC
                    <br/>
                    Minted: XXXXX/XXXXX
                </p>
                <MintButton dispatch={props.dispatch} blockchain={props.blockchain}/>
            </div>
    
            <div className="gpt3__header-image">
                <img src={home} alt="ai"/>
            </div> 
        </div>
    );
}

export default Header;