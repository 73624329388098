import React from "react";
import VerticalTimeline from '../../components/timeline/VerticalTimeline';
import VerticalTimelineElement from '../../components/timeline/VerticalTimelineElement';
import ViewInArSharpIcon from '@mui/icons-material/ViewInArSharp';
import "../../components/timeline/VerticalTimeline.css";
import "../../components/timeline/VerticalTimelineElement.css";
import './features.css';

function Features() {

    return (
        <div className='root'>
            <h1 className="gradient__text">The Roadmap</h1>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ color: 'white' }}
                    date="Dec 2022"
                    iconStyle={{ background: '#408896', color: '#fff' }}
                    icon={<ViewInArSharpIcon />}
                >
                    <h4 className="vertical-timeline-element-subtitle">> Launch Landing Page</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Initial Marketing Push</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{color: 'white' }}
                    date="Jan 2023"
                    iconStyle={{ background: '#408896', color: '#fff' }}
                    icon={<ViewInArSharpIcon />}
                >
                    <h4 className="vertical-timeline-element-subtitle">> Initiate Price and Data Mining</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Finalise Design and Architecture</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ color: 'white' }}
                    date="Feb 2023 - Jul 2023"
                    iconStyle={{ background: '#2ec7cc', color: '#fff' }}
                    icon={<ViewInArSharpIcon />}
                >
                    <h4 className="vertical-timeline-element-subtitle">> Whitepaper Release</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Building and Development</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Deploy on Testnet</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Community-Driven Beta Testing</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Audits</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ color: 'white' }}
                    date="Aug 2023 - Sep 2023"
                    iconStyle={{ background: '#2ec7cc', color: '#fff' }}
                    icon={<ViewInArSharpIcon />}
                >
                    <h4 className="vertical-timeline-element-subtitle">> NFT Drop: Crowdfunding</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Marketing Campaigns</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Final Functional, End-to-End and Performance Testing</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Deploy on Mainnet</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Partnerships for Educational Content</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    contentStyle={{ color: 'white' }}
                    date="Oct 2023 - Nov 2023"
                    iconStyle={{ background: '#8ee2e8', color: '#fff' }}
                    icon={<ViewInArSharpIcon />}
                >
                    <h4 className="vertical-timeline-element-subtitle">> $QVM ICO and Initial Liquidity for DEXs</h4>
                    <h4 className="vertical-timeline-element-subtitle">> $QVM Airdrop for NFT Holders</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Roll Out Complete Functionality</h4>
                    <h4 className="vertical-timeline-element-subtitle">> Publish Educational Content</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    contentStyle={{ color: 'white' }}
                    date="Dec 2023 - "
                    iconStyle={{ background: '#8ee2e8', color: '#fff' }}
                    icon={<ViewInArSharpIcon />}
                >
                    <h4 className="vertical-timeline-element-subtitle">> Operations and Future Extensions</h4>

                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    );
}

export default Features;