import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
/*import { Link } from 'react-router-dom';*/
import logo from '../../assets/images/website/quandefy_logo_nobg.png';
/*import ConnectButton from "../buttons/ConnectButton";*/
import ConnectButtonDisabled from "../buttons/ConnectButtonDisabled";
import './navbar.css';

function Navbar(props) {

    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <div className="gpt3__navbar">
            <div className="gpt3__navbar-links">
                <div className="gpt3__navbar-links_logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="gpt3__navbar-links_container">
                    <p id="closed_link">Whitepaper</p>
                    <p><a href="https://twitter.com/quandefy" target="_blank" rel="noopener noreferrer">Twitter</a></p>
                    {/*<p><a href="https://t.me/quandefy" target="_blank" rel="noopener noreferrer">Telegram</a></p>*/}
                    <p id="closed_link">Telegram</p>
                    <p id="closed_link">Etherscan: ERC721</p>
                </div>
            </div>
            <div className="gpt3__navbar-sign">
                {/*<ConnectButton dispatch={props.dispatch} blockchain={props.blockchain}/>*/}
                <ConnectButtonDisabled />
            </div>
            <div className="gpt3__navbar-menu">
            {toggleMenu
                ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
            {toggleMenu && (
            <div className="gpt3__navbar-menu_container scale-up-center">
                <div className="gpt3__navbar-menu_container-links">
                    <p id="closed_link">Whitepaper</p>
                    <p><a href="https://twitter.com/quandefy" target="_blank" rel="noopener noreferrer">Twitter</a></p>
                    {/*<p><a href="https://t.me/quandefy" target="_blank" rel="noopener noreferrer">Telegram</a></p>*/}
                    <p id="closed_link">Telegram</p>
                    <p id="closed_link">Etherscan: ERC721</p>
                </div>
                <div className="gpt3__navbar-menu_container-links-sign">
                    {/*<ConnectButton dispatch={props.dispatch} blockchain={props.blockchain}/>*/}
                    <ConnectButtonDisabled />
                </div>
            </div>
            )}
            </div>
        </div>
    );
}

export default Navbar;