import React from 'react';

import './sectionheader.css';

function SectionHeader() {

    return (
        <div className="section_header">
            <h1 className="gradient__text">Enhance Strategies with Data-Driven Decision-Making</h1>
            <p>Quandefy is the DeFi retail investor's solution for the design and implementation of ML empowered strategies.</p>
        </div>
    );
}

export default SectionHeader;