import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = theme => ({
	accordion: {
		backgroundColor: 'transparent',
		color: 'black',
		padding: '0.25em',
		marginTop: '0.5em',
		borderStyle: 'solid',
		boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
		[theme.breakpoints.down('400')]: {
			padding: '0',
		}
	},
	heading: {
		fontSize: theme.typography.pxToRem(14),
		fontFamily: `'Manrope', sans-serif`,
		fontWeight: 'bold',
		overflowWrap: 'normal',
		[theme.breakpoints.down('400')]: {
			fontSize: theme.typography.pxToRem(14),
			overflowWrap: 'anywhere',

		}
  	},
	details: {
		fontFamily: `'Manrope', sans-serif`,
		fontSize: theme.typography.pxToRem(14),
		overflowWrap: 'normal'
	},
	icon: {
		color: 'black'
	}
})

class Faq extends Component {

  render() {

    const { classes } = this.props

    return (
      <div className={classes.accordionRoot}>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes.icon} />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Who can use Quandefy?</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.accordionDetails}>
						<Typography className={classes.details}>
							Anyone with a web3 wallet can use Quandefy. <br/><br/>
							Any investor who wants to strategically accumulate crypto during the bear market can use Quandefy. <br/><br/>
							Any trader who wants to deploy their own automatic algorithmic trading bots can use Quandefy. <br/><br/>
							Any copy trader who wants to capitalise on profitable strategies can use Quandefy.<br/><br/>
							Any innovator who wants to experiment with and implement data-driven decision making through ML empowered 
							strategies can use Quandefy.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes.icon} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.heading}>What if I don't know how to code?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.details}>
							That's perfectly fine! Quandefy aims to provide intuitive no-code tools for users to specify their conditional buy and 
							sell signals. 
							Even the complex and tedious process of defining, training and optimising ML models will be broken down into simple, 
							easy-to-follow steps. <br/><br/>
							Furthermore, Quandefy's educational content will provide the more inexperienced users a better understanding of the 
							underlying rationale without diving too deep into the convoluted technical and theoretical details. (Although in-depth and 
							advanced educational content is not out of the question)
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes.icon} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.heading}>How is Quandefy's solution unique?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.details}>
							Quandefy will pioneer retail solutions for ML and data-driven trading strategies in the DeFi space.
							This one-stop solution to define, test, optimise and deploy automated strategies will empower DeFi traders like never before. 
							Why should such tools and infrastructure only be accessible to the institutional traders?
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes.icon} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.heading}>Is Quandefy an NFT project?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.details}>
							Yes and no. Quandefy's main utility will be the DeFi platform itself. However, NFTs will also be used as a medium 
							for initial crowdfunding and to best reward the earliest investors whose benefits will grow as the project does. And hey, 
							a PFP reveal is not completely out of the question, it's good marketing anyways ;).
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes.icon} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.heading}>What can I do with the native tokens?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.details}>
							Equivium ($QVM) will not just be an investment instrument or speculative asset. Quandefy's entire ecosystem will run on 
							$QVM utility tokens. Users will have to hold or pay fees in $QVM in order to utilise the platform, consequently also 
							stimulating demand for the token. 
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes.icon} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.heading}>Will my funds be safe?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.details}>
							Yes! Quandefy will not centrally hold any user funds. Records of equity of investment pools and the funds themselves will be 
							stored on the blockchain where the smart contracts reflecting our protocols are transparent and immutable.
							The profitability of bots and their built-in strategies however, are outside of our control (except for the in-house Quandefy bots).
						</Typography>
					</AccordionDetails>
				</Accordion>
      </div>
    );
  }
}

export default withStyles(useStyles)(Faq);