import React from "react";
import './nftutility.css';
import Feature from '../../components/feature/Feature';

function WhatGPT3() {

    return (
        <div className="gpt3__whatgpt3 section__margin" id="utility">
            <div className="gpt3__whatgpt3-feature">
                <Feature title="NFT Utility" text="Quandium NFT drop will act as the first round of funding in order to reinvest in quality 
                manpower and software infrastructure. This is also the best way to reward the earliest adopters, with benefits, utilities and 
                returns that grow with the project." />
            </div>
            <div className="gpt3__whatgpt3-heading">
                <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
            </div>
            <div className="gpt3__whatgpt3-container">
                <Feature title="Participate in Beta Testing" text="Extensive involvement in the building process" />
                <Feature title="Pioneer" text="Be the first users of the Quandefy platform" />
                <Feature title="Airdrop" text="Elligible for airdrops of native utility tokens upon deployment" />
                <Feature title="Unlimited Access" text="Utilise the Quandefy platform indefinitely without fees" />
                <Feature title="Governance" text="Participate in on-chain governance protocols" />

            </div>
        </div>
    );
}

export default WhatGPT3;