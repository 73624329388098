import React from "react";
import '../../App.css';
import Blog from '../blog/Blog';
import Features from '../features/Features';
import Build from '../possibility/Build';
import Stake from "../possibility/Stake";
import WhatGPT3 from '../whatGPT3/WhatGPT3';
import CTA from '../../components/cta/CTA';
/*import Brand from '../../components/brand/Brand';*/
import Header from '../header/Header';
import Navbar from '../../components/navbar/Navbar';
import SectionHeader from '../../components/sectionheader/SectionHeader';

function Home(props) {

    return (
        <div className="App">
            <div className="gradient__bg">
                <Navbar dispatch={props.dispatch} blockchain={props.blockchain}/>
                <Header dispatch={props.dispatch} blockchain={props.blockchain}/>
            </div>
            {/*<Brand />*/}
            <Blog />
            <SectionHeader />
            <Build />
            <Stake />
            <WhatGPT3 />
            <Features />
            <CTA />                        
        </div>
    );
}

export default Home;