import React from 'react';
import logo from '../../assets/images/website/quandefy_logo_nobg.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p id="closed_link">Whitepaper</p>
        <p><a href="https://twitter.com/quandefy" target="_blank" rel="noopener noreferrer">Twitter</a></p>
        {/*<p><a href="https://t.me/quandefy" target="_blank" rel="noopener noreferrer">Telegram</a></p>*/}
        <p id="closed_link">Telegram</p>
        <p id="closed_link">Etherscan: ERC721</p>
      </div>
    </div>
    <div className="gpt3__footer-links_logo">
      <img src={logo} alt="Quandefy Logo" />
      <p className="gradient__text">Quandefy</p>
    </div>
  </div>
);

export default Footer;