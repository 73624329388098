import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Footer from './containers/footer/Footer';
import Home from './containers/home/Home'
import './App.css';

function App() {

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home dispatch={dispatch} blockchain={blockchain}/>} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;