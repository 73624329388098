import React from 'react';
import Article from '../../components/article/Article';
import lab from '../../assets/images/website/lab.png';
import marketplace from '../../assets/images/website/marketplace.png';
import educational from '../../assets/images/website/educational.png';
import tools from '../../assets/images/website/tools.png';


import './blog.css';

function Blog() {

    return (
        <div className="gpt3__blog section__padding" id="blog">
            <div className="gpt3__blog-heading">
                <h1 className="gradient__text">Quandefy<br /> Platform</h1>
            </div>
            <div className="gpt3__blog-container">
                <div className="gpt3__blog-container_groupB">
                    <Article imgUrl={lab} text="Automated Bots" subtext="Define strategies, implement ML models, backtest and deploy automated trading bots"/>
                    <Article imgUrl={marketplace} text="Marketplace" subtext="List custom bots and earn fees, or stake funds in listed bots"/>
                    <Article imgUrl={educational} text="Educational Content" subtext="Blog posts and videos to expand domain knowledge"/>
                    <Article imgUrl={tools} text="Tools for Retail Traders" subtext="Charts, technical indicators, portfolio visualisation, sentiment analysis"/>
                </div>
            </div>
        </div>
    );
}

export default Blog;