import React from 'react';
import './connectbutton.css';

function ConnectButtonDisabled(props) {


    return (
        <button className="connect_button" id="connected" type="button"><span>CONNECT WALLET</span></button>
    );
    
}

export default ConnectButtonDisabled;