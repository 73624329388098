import React, { useState } from "react";
import { fetchData } from "../../redux/data/dataActions";
import { CircularProgress } from "@material-ui/core";
import './mintbutton.css';

function MintButton(props) {

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(1);

    const mint = (account, numCats) => {
        setLoading(true);
        props.blockchain.contract.methods
            .mintGrimalkin(numCats)
            .send({
                from: account,
                value: 0.08 * Math.pow(10, 18) * numCats,
            })
            .once("error", (err) => {
                setLoading(false);
                console.log(err);
            })
            .then((receipt) => {
                setLoading(false);
                console.log(receipt);
                props.dispatch(fetchData(account));
            });
    }
    
    const incrementCount = () => {
        if (count < 50) {
            setCount(count + 1)
        }
    }

    const decrementCount = () => {
        if (count > 1) {
            setCount(count - 1)
        }
    }

    const handleCountInput = (e) => {
        if (e.target.value <= 50 && e.target.value > 0) {
            setCount(parseInt(e.target.value));
        }
    }

    if (loading) {
        // case: transaction processing
        return (
            <div className="disabled_mint_button_container">
                <button disabled>
                    <CircularProgress className="circular_progress" color="inherit" size="0.8rem" thickness={10}/>
                </button>
                <div className="counter_group">
                    <button disabled id="minus">-</button>
                    <input disabled type="number" className="num_input" value={count} onChange={handleCountInput} min="1" max="50"></input>
                    <button disabled id="plus">+</button>
                </div>        
            </div>
        );
    } else if (props.blockchain.account !== null && props.blockchain.account !== undefined) {
        // case: account connected
        return (
            <div className="mint_button_container">
                <button 
                    type="button" 
                    onClick={(e) => {
                    e.preventDefault();
                    mint(props.blockchain.account, parseInt(count));
                }}>
                    MINT
                </button>
                <div className="counter_group">
                    <button id="minus" onClick={decrementCount}>-</button>
                    <input type="number" className="num_input" value={count} onChange={handleCountInput} min="1" max="50"></input>
                    <button id="plus" onClick={incrementCount}>+</button>
                </div>        
            </div>
        );
    } else {
        // case: no wallet connected
        return (
            <div className="disabled_mint_button_container">
                <button disabled>
                    MINT                    
                </button>
                <div className="counter_group">
                    <button disabled id="minus">-</button>
                    <input disabled type="number" className="num_input" value="" onChange={handleCountInput} min="1" max="50"></input>
                    <button disabled id="plus">+</button>
                </div>        
            </div>
        );
    }
}

export default MintButton;