import React from "react";
import buildImg from '../../assets/images/website/build.png';

import './possibility.css';

function Build() {

    return (
        <div className="gpt3__possibility section__padding" id="possibility">
            <div className="gpt3__possibility-content">
                <h1 className="gradient__text">Build</h1>
                    <ul>
                        <li>Build and deploy automated trading bots using intuitive no-code tools.</li>
                        <li>Define conditional buy and sell signals with respect to price or popular technical indicators.</li>
                        <li>Supplement bots with custom ML models that can be trained, tested and optimised on-site.</li>
                        <li>Backtest bot using historical data or run live simulations using real-time price data.</li>
                        <li>List bot on the marketplace to earn fees from copy traders, and split on gas fees.</li>
                   </ul>
            </div>
            <div className="gpt3__possibility-image">
                <img src={buildImg} alt="possibility" />
            </div>
        </div>
    );
}

export default Build;