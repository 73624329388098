import React from "react";
import stake from '../../assets/images/website/stake.png';

import './possibility.css';

function Stake() {

    return (
        <div className="gpt3__possibility section__padding" id="collaborate_reverse">
            <div className="gpt3__possibility-image" id="div_image_stake">
                <img src={stake} alt="possibility" />
            </div>
            <div className="gpt3__possibility-content">
                <h1 className="gradient__text">Stake</h1>
                    <ul>
                        <li>Utilise listed peer-built bots to capitalise on profitable strategies.</li>
                        <li>Utilise in-house Quandefy bots for strategies including:</li>
                            <ul id='nested_list'>
                                <li>Buying the dip</li>
                                <li>DCA</li>
                                <li>Support & Resistance</li>
                                <li>ICO Sniper (With Sentiment Analysis)</li>
                            </ul>
                        <li>Profits split by equity of investment pool.</li>
                        <li>Gas fees split proportionally.</li>
                   </ul>
            </div>
        </div>
    );
}

export default Stake;